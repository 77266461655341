<template>
  <b-container fluid class="p-0">
    <slot name="title">
      <h5>
        {{ title }}
      </h5>
    </slot>
    <b-row v-for="(item, index) in items" :key="getID(`${item.value}-${index}`)" class="position-relative">
      <b-col md="10">
        <custom-input
          v-if="variables"
          :key="`custom-input-${index}-${version}`"
          :id="`custom-input-${index}`"
          :possibleValues="variables"
          advanced
          v-model="items[index][setRegister]"
          @value-changed="version++"
        />
        <b-skeleton v-else class="w-100" height="37px"/>
      </b-col>
      <b-col md="2">
        <b-dropdown
          v-if="!loadingDelete[index]"
          id="dropdown-dropleft"
          variant="outline-danger"
          right
          no-caret
          toggle-class="px-1 py-75 mt-25 rounded"
          menu-class="rounded bg-variant-dark-blue"
        >
          <template #button-content>
            <feather-icon
              icon="TrashIcon"
            />
          </template>
          <div
            class="text-center"
          >
            <span class="text-light-gray">
              {{ $t('common.terms.are_you_sure') }}
            </span>
            <b-button
              class="p-50 rounded-pill"
              variant="flat-secondary"
              @click="deleteItem(index)"
            >
              <feather-icon
                icon="CheckIcon"
                size="15"
                class="text-success"
              />
            </b-button>
          </div>
        </b-dropdown>
        <b-button 
          v-else
          variant="flat-danger"
        >
          <b-spinner small label="Loading..." />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-end">
      <b-col>
        <b-button
          id="add-custom-input"
          @click="addItem"
          class="py-50 px-75 text-capitalize"
          variant="outline-success"
        >
          <feather-icon
            icon="PlusIcon"
          />
          {{ $t('common.terms.add') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BSidebar,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BTab,
  BFormSelect,
  BTabs,
  BFormSelectOption,
  BFormCheckbox,
  BSkeleton,
  BDropdown,
  BDropdownItem,
  BPopover,
  BSpinner
} from "bootstrap-vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import { makeToast } from "@/layouts/components/Popups";
import HelperTooltip from "@/layouts/components/HelperTooltip.vue";
import { v4 as uuidv4 } from "uuid";
import Agent from '@/custom/class/Agent/Agent.js'
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent.js'
import NormalizedAgent from '@/custom/class/NormalizedAgent.js'
import Source from '@/custom/class/Agent/Source'
import Sources from '@/custom/class/Enum/Sources.js'

  export default {
    components: {
      CustomInput,
      HelperTooltip,
      BContainer,
      BSidebar,
      BRow,
      BCol,
      BButton,
      BFormInput,
      BInputGroup,
      BInputGroupAppend,
      BInputGroupPrepend,
      BFormGroup,
      BForm,
      BTab,
      BFormSelect,
      BTabs,
      BFormSelectOption,
      BFormCheckbox,
      BSkeleton,
      BDropdown,
      BDropdownItem,
      BPopover,
      BSpinner
    },
    props: {
      middlewareID: {
        type: Number,
        required: true,
      },
      defaultValue: {
        type: undefined,
        default: ''
      },
      defaultAgentActionID: {
        type: Number,
        default: 3
      },
      variables: {
        type: Object,
        required: true
      },
      value: {
        type: Array,
        default: () => []
      },
      title: {
        type: String,
        default: 'List'
      },
      changerOptions: {
        type: Array,
        default: () => [] 
      },
      source: {
        type: [ String, Number ],
        default: undefined
      },
      setRegister: {
        type: String,
        default: "register_1"
      },
      defaultAgentBlockID: {
        type: [ Number, String ],
        default: "1"
      },
      defaultAgentValue: {
        type: NormalizedAgent,
        required: true
      }
    },
    data() {
      return {
        uuidMap: {},
        loadingDelete: [],
        version: 0
      }
    },
    computed: {
      items: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      agent_block_id() {
        return this.defaultAgentBlockID
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      addItem() {
        this.items.push(
          this.defaultAgentValue
        )
        this.$emit('itemAdded')
      },
      deleteItem(index) {
        this.$set(this.loadingDelete, index, true)
        this.$emit('deleteItem', index)
      },
      // getAgentEnumByID(id) {
      //   return new Agent(id)
      // },
      getSource(id) {
        return new Source(id);
      },
    }
  }
</script>

<style lang="scss">

.limit-height {
  height: calc(100% - 215px) !important;
}

.control-height {
  position: relative !important;
  // overflow: auto !important;
}

</style>
